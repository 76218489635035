import React from "react";
import { Link } from "gatsby";
import { HomeIcon } from "./HomeIcon";
import {motion} from 'framer-motion'
import {textAnimation, textAni} from './animation';
function NotfoundText({heading, body}) {
 
  return (
    <>
      <motion.div
        className="not--found--text"
        variants={textAni}
        initial="hidden"
        animate="visible"
      >
        <motion.h1 variants={textAnimation}>{heading}</motion.h1>
        <motion.p variants={textAnimation}>{body}</motion.p>
        <Link to="/">
          <HomeIcon />
          Guide him to Home page
        </Link>
      </motion.div>
    </>
  );
}

export { NotfoundText };
