import React from "react";
import { NotfoundText } from "./NotfoundText";
import { NotfoundImage } from "./NotfoundImage";
import { useStaticQuery, graphql } from "gatsby";

function Notfound() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulNotfoundpage {
        nodes {
          heading
          body
          illustration {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `);

  const {
    allContentfulNotfoundpage: {
      nodes: [{ heading, body, illustration }],
    },
  } = data;
console.log(illustration);
  return (
    <>
      <main className="not--found--main">
        <div className="not--found--wrapper">
          <NotfoundText heading={heading} body={body} />
          <NotfoundImage image={illustration} />
        </div>
      </main>
    </>
  );
}

export { Notfound };
