import React from "react";
import Image from "gatsby-image";
import { motion } from "framer-motion";
import { imageAnimation } from "./animation";
function NotfoundImage({image}) {
  return (
    <>
      <motion.div className="not--found--page--image" variants={imageAnimation} initial='hidden' animate='visible'>
          <Image fluid={image.fluid}/>
      </motion.div>
    </>
  );
}

export { NotfoundImage };
