import * as React from "react";
import { Notfound } from "../Components/Notfound";

// markup
const NotFoundPage = () => {
  return (
    <>
      <Notfound />
    </>
  );
};

export default NotFoundPage;
