import React from "react";

function HomeIcon() {
  return (
    <>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="home"
      >
        <path
          d="M19.6487 7.8616L17.1463 5.82207V2.56028C17.1463 2.19297 16.8474 1.8941 16.4801 1.8941H14.9055C14.5382 1.8941 14.2393 2.19297 14.2393 2.56028V3.45277L10.8955 0.72756C10.6536 0.530493 10.3348 0.421906 9.99788 0.421906C9.66225 0.421906 9.34497 0.529826 9.10442 0.725864L0.351261 7.86154C-0.0464469 8.18561 -0.0225857 8.46788 0.0270143 8.6073C0.0764932 8.74641 0.235831 8.97981 0.746729 8.97981H1.9453V18.3637C1.9453 19.0333 2.48866 19.5781 3.15654 19.5781H6.94164C7.60388 19.5781 8.12259 19.0446 8.12259 18.3637V14.5155C8.12259 14.1857 8.4257 13.8854 8.75849 13.8854H11.3021C11.6193 13.8854 11.8774 14.1681 11.8774 14.5155V18.3637C11.8774 19.022 12.446 19.5781 13.1189 19.5781H16.8435C17.5113 19.5781 18.0547 19.0333 18.0547 18.3637V8.97981H19.2533C19.7642 8.97981 19.9235 8.74641 19.973 8.6073C20.0226 8.46788 20.0465 8.18561 19.6487 7.8616Z"
          fill="#FDFCFD"
        />
      </svg>
    </>
  );
}

export { HomeIcon };
